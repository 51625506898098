import NewArrivals from "../product-cards/NewArrivals";
import { useHomeContext } from "../../contexts/app/HomeContext.tsx";
import { useAppContext } from "../../contexts/app/AppContext";
import Box from "../Box";
import React, { useEffect, useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import Carousel from "../carousel/Carousel";
import CategorySectionCreator from "../CategorySectionCreator";
import ProductCard1 from "../product-cards/ProductCard1";
import NewArrivalSectionStyle from "./NewArrivalSectionStyle";


const NewArrivalSection: React.memo = () => {

    const screenWidth: any = useWindowSize()

    const { newArrivals } = useHomeContext()
    const { fixPrice } = useAppContext()
    const [showPage, setShowPage] = useState(true)
    //console.log(newArrivals)
    const desktopButtonStyle = {
        position: 'absolute',
        transform: 'translateY(-80%)',
        top: '45%',
        backgroundColor: '#21212180', // Background color with higher opacity
        borderRadius: '50%', // Makes the button circular
    };

    const mobileButtonStyle = {
        backgroundColor: '#21212180', // Dark grey background with higher opacity
        borderRadius: '50%', // Makes the button circular
        width: '37px', // Adjust width for circular shape
        height: '37px', // Adjust height for circular shape
        display: 'flex',
        top: '34%',
        justifyContent: 'center',
        alignItems: 'center',
        'svg' : {
            width:10
        }
    };

    const baseButtonStyle = screenWidth < 768 ? mobileButtonStyle : desktopButtonStyle;

    const leftButtonStyle = {
        ...baseButtonStyle,
        left: '0',

    };

    const rightButtonStyle = {
        ...baseButtonStyle,
        right: '0',


    };

    return (
        <NewArrivalSectionStyle>
            <CategorySectionCreator
                iconName="new-product-1"
                title="New Arrivals"
                seeMoreLink="#"
            />
            <Box mt={'8rem'} mb="-0.25rem" className="inner-new-prod">
                <Carousel totalSlides={newArrivals.length}
                          showArrow={true}
                          visibleSlides={screenWidth > 768 ? 4 : 2}
                          spacing={'5px'}
                          infinite={false}
                          leftButtonStyle={leftButtonStyle}
                          rightButtonStyle={rightButtonStyle}

                >
                    {newArrivals && newArrivals.map((item) => (
                        <Box py="0.25rem" key={item.id}>
                            {/*price={product?.is_package === 1 || product?.show_total_price === 0 ? product?.product_price_discount : product?.price}*/}

                            <ProductCard1
                                id={item.id}
                                sku={item.sku}
                                setShowPage={setShowPage}
                                urlKey={item.url_key}
                                imgUrl={item.base_image['medium_image_url']}
                                title={item.name}
                                rating={4}
                                //price={parseFloat(item.formatted_price.replace(/[^0-9.-]+/g,""))}
                                price={item.show_total_price ? fixPrice(parseFloat(item.price)) : fixPrice(parseFloat(item?.product_price_discount))}
                                off={item.off}
                                key={item.id}
                                variants={item.variants ?? []}
                                attributes={item.attributes}
                                className='newarrival'
                                initialPrice={item.is_package || !item.show_total_price ? fixPrice(parseFloat(item.product_price)) : fixPrice(parseFloat(item.initialPrice))}

                            />
                        </Box>
                    ))}
                </Carousel>
            </Box>


        </NewArrivalSectionStyle>
    );
};



export default NewArrivalSection;