import React from 'react'
import styled from 'styled-components'
import Box from '../Box'

const NewArrivalSectionStyle = styled(Box)`
    margin-top : 12rem;
    margin-bottom : 3rem;
    overflow : visible;
    .carousel {
        margin : auto;
        width : 90%;
    }

    .title{
        font-size : 1.2vw;
    }

    .newarrival{
        overflow:visible;
    }

    .details{
        width : 100%;
    }

    @media only screen and (max-width : 768px){
        margin-top : 6rem;

        iiimg{
            width : 146px;
            min-width:unset;
            height:146px;
        }

        svg{
            width: 25px;
            height: 25px;
            margin-left: 3px;
            margin-top: 3px;
        }

        .title{
            font-size : 12px;

        }

        .inner-new-prod{
            margin-top : 0rem;
        }
    }

    @media only screen and (max-width : 480px){

        .size{
            min-width : 18px;
            font-size : 10px;
        }

        .diat {
            font-size : 10px;
        }

    }


`

export default NewArrivalSectionStyle